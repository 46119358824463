/*
  ==============================
  IMPORTANT
  ==============================
  DO NOT ADD ANYTHING HERE THAT MAY INCREASE THE SIZE OF THE BUNDLE.

  This file is imported in the main bundle of the SDK.

  DO NOT:
    - Import from external dependencies such as material-ui.
    - Import components that depend on external libraries.
*/

export const ORANGE = {
  50: '#FEF9F0',
  100: '#FDEED8',
  200: '#FBDEB1',
  300: '#F8BE67',
  400: '#FF9800',
  500: '#E07800',
  600: '#D15920',
  700: '#A9401B',
  800: '#843417',
  900: '#682E16',
};

export const GREEN = {
  50: '#F5FBF6',
  100: '#E8F4EC',
  200: '#CDE9D7',
  300: '#A6DAB5',
  400: '#7BC58D',
  500: '#5CB06D',
  600: '#449752',
  700: '#0A853B',
  800: '#106A34',
  900: '#0C4926',
};

export const BLUE = {
  50: '#F4F8FD',
  100: '#EDF2FD',
  200: '#C2D5FF',
  300: '#9CB7FF',
  400: '#6683FF',
  500: '#4258FF',
  600: '#363DF5',
  700: '#2A2ED8',
  800: '#252AAE',
  900: '#262C89',
};

export const YELLOW = {
  50: '#FEFCEB',
  100: '#FFFAC2',
  200: '#FFF189',
  300: '#FFDF39',
  400: '#FDCD12',
  500: '#ECB306',
  600: '#CC8B02',
  700: '#A36205',
  800: '#864D0D',
  900: '#5F340E',
};

export const RED = {
  50: '#FEF3F2',
  100: '#FFE7E5',
  200: '#FFCCC8',
  300: '#FFA8A2',
  400: '#FC776D',
  500: '#F44336',
  600: '#D9291C',
  700: '#BE2217',
  800: '#9D2017',
  900: '#551611',
};

export const GRAY = {
  50: '#F7F7F8',
  100: '#EFEFF1',
  200: '#E2E3E5',
  300: '#CED0D5',
  400: '#B5B9BF',
  500: '#8D929C',
  600: '#6F767B',
  700: '#5C616A',
  800: '#4C5057',
  900: '#212326',
};

export const ICE_BLUE = '#EAF7FE';
export const PURPLE_ABYSS = '#160833';
export const BRIGHT_PURPLE = '#8060FF';
export const LIME_GREEN = '#21BF60';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

export const FONT_FAMILY = '"Inter", "Helvetica", "Arial", sans-serif';
export const DEFAULT_PRIMARY_COLOR = GREEN[700];

export type LOADING_STYLE = 'default' | 'minimal';
export const LOADING_DEFAULT: LOADING_STYLE = 'default';
export const LOADING_MINIMAL: LOADING_STYLE = 'minimal';

export const DEFAULT_THEME: any = {
  primaryColor: {
    main: GREEN[700],
    dark: GREEN[800],
    light: GREEN[100],
  },
  backgroundColor: WHITE,
  fontFamily: FONT_FAMILY,
  type: 'light',
};
