import React from 'react';
import ReactDOM from 'react-dom';

/*
  ==============================
  IMPORTANT
  ==============================
  DO NOT ADD ANYTHING HERE THAT MAY INCREASE THE SIZE OF THE BUNDLE.

  This file must be as small as possible. It is the entry point of the SDK.

  If you need to add a new dependency, include it inside of app.tsx
  or use React.lazy to load it asynchronously.
*/
import { Loading } from './components/loading';
import { AnimatedDot } from './components/animated_dot';
import { FONT_FAMILY, LOADING_STYLE, LOADING_MINIMAL } from './constants';
import './assets/fonts.css';

const App = React.lazy(() =>
  Promise.all([
    import(/* webpackChunkName: "app" */ './app'),
    import(/* webpackChunkName: "setup-uikit" */ '@passthrough/uikit/setup'),
    new Promise((resolve) => setTimeout(resolve, 200)),
  ]).then(([moduleExports]) => moduleExports),
);

const LoadingPage = ({ fontFamily, backgroundColor, type, loadingStyle }) => (
  <div
    style={{
      fontFamily,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.875rem',
      width: '100%',
      color: type === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.87)',
      boxSizing: 'border-box',
      fontWeight: 400,
      lineHeight: '1.43',
      backgroundColor: backgroundColor || 'inherit',
    }}
  >
    <Loading>
      {loadingStyle === LOADING_MINIMAL ? <AnimatedDot /> : null}
    </Loading>
  </div>
);

function parseJWTPayload(token: string) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return {};
  }
}

const PassthroughSDK = {
  init: ({
    elementId,
    token,
    theme,
    onFinish,
    onExpire,
    onSupport,
  }: {
    elementId: string;
    token: string;
    theme?: {
      primaryColor?: {
        main: string;
        light: string;
        dark: string;
      };
      fontFamily?: string;
      type?: 'dark' | 'light';
      backgroundColor?: string;
      loadingStyle?: LOADING_STYLE;
    };
    onFinish?: ({ action }: { action: string }) => any;
    onExpire?: () => any;
    onSupport?: () => any;
  }) => {
    const container = document.getElementById(elementId);

    if (container === null) {
      throw Error(`Element with id ${elementId} not found`);
    } else {
      const payload = parseJWTPayload(token);
      const fontFamily = theme?.fontFamily || FONT_FAMILY;
      ReactDOM.render(
        <React.Suspense
          fallback={
            <LoadingPage
              fontFamily={fontFamily}
              backgroundColor={theme?.backgroundColor}
              type={theme?.type}
              loadingStyle={theme?.loadingStyle}
            />
          }
        >
          <App
            // re-render the app when it's a new investor closing
            key={payload.investor_closing_id}
            elementId={elementId}
            token={token}
            theme={theme}
            viewType={payload.view_type}
            hostUrl={payload.host_url}
            onFinish={onFinish}
            onExpire={onExpire}
            onSupport={onSupport}
          />
        </React.Suspense>,
        container,
      );
    }
  },
};

declare global {
  interface Window {
    PassthroughSDK: typeof PassthroughSDK;
  }
}

window.PassthroughSDK = PassthroughSDK;
