import React from 'react';
import './loading.css';

/*
  ==============================
  IMPORTANT
  ==============================
  DO NOT ADD ANYTHING HERE THAT MAY INCREASE THE SIZE OF THE BUNDLE.

  This component is imported in the main bundle of the SDK. It is displayed
  while the initial script is downloading the main app and its vendors.

  DO NOT:
    - Import from external dependencies such as material-ui.
    - Import components that depend on external libraries.

  Add new css rules to loading.css to avoid importing material-ui.
*/
export const Loading = ({ children }: { children?: any }) => (
  <div className="pt-sdk-loading__root">
    <div>
      {children}
      <div className="pt-sdk-loading__text">
        Preparing your subscription document...
      </div>
    </div>
  </div>
);
