import React from 'react';
import './animated_dot.css';

/*
  ==============================
  IMPORTANT
  ==============================
  DO NOT ADD ANYTHING HERE THAT MAY INCREASE THE SIZE OF THE BUNDLE.

  This component is imported in the main bundle of the SDK. It is displayed
  while the initial script is downloading the main app and its vendors.

  DO NOT:
    - Import from external dependencies such as material-ui.
    - Import components that depend on external libraries.

  Add new css rules to animated_dot.css to avoid importing material-ui.
*/
export const AnimatedDot = () => (
  <svg
    width="36"
    height="4"
    viewBox="0 0 36 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle className="pt-sdk-dot-loading" cx="2" cy="2" r="2" fill="#6F767B" />
    <circle
      className="pt-sdk-dot-loading"
      cx="10"
      cy="2"
      r="2"
      fill="#6F767B"
    />
    <circle
      className="pt-sdk-dot-loading"
      cx="18"
      cy="2"
      r="2"
      fill="#6F767B"
    />
    <circle
      className="pt-sdk-dot-loading"
      cx="26"
      cy="2"
      r="2"
      fill="#6F767B"
    />
    <circle
      className="pt-sdk-dot-loading"
      cx="34"
      cy="2"
      r="2"
      fill="#6F767B"
    />
  </svg>
);
